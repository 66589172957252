import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, finalize } from 'rxjs/operators';
import { SpinnerService } from '@admin-services/spinner.service';
import { NbToastrService } from '@nebular/theme';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  private auth: Auth = inject(Auth);
  idToken$ = idToken(this.auth);

  constructor(
    private spinner: SpinnerService,
    private toastrService: NbToastrService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.idToken$.pipe(
      exhaustMap((token: string) => {
        const header = { Authorization: 'Bearer ' + token };

        if (token) req = req.clone({ setHeaders: header });
        if (!req.url.includes('/packages/sort')) this.spinner.show();

        return next.handle(req).pipe(
          catchError((err) => {
            if (err.status === 403) {
              this.toastrService.show('You are not authorized', 'Auth Error!', {
                status: 'warning',
              });
            }

            return of(err);
          }),
          finalize(() => this.spinner.hide())
        );
      })
    );
  }
}
