import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NbIconLibraries } from '@nebular/theme';
import { PrimeNGConfig } from 'primeng/api';

import { SpinnerService } from './services/spinner.service';
import { target_components } from './configs/target-components';
import { SIDENAV_ICON_PACK } from '@admin-constants/icon-packs';

const sourceButtons = ['AppointmentUsers', 'ListGrid'];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions$ = new Subscription();

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private loadingSpinner: SpinnerService,
    private primengConfig: PrimeNGConfig,
    private iconLibraries: NbIconLibraries
  ) {
    this.subscriptions$.add(
      this.router.events.subscribe({
        next: (e) => {
          if (e instanceof NavigationStart) this.loadingSpinner.show();
          if (
            e instanceof NavigationEnd ||
            e instanceof NavigationCancel ||
            e instanceof NavigationError
          ) {
            this.loadingSpinner.hide();
          }
        },
      })
    );

    this.iconLibraries.registerFontPack('font-awesome', {
      iconClassPrefix: 'fa',
      packClass: 'far',
    });

    for (let prop in target_components) {
      for (let cusTabBtn of sourceButtons) {
        sessionStorage.removeItem(`${target_components[prop]}-${cusTabBtn}`);
      }
    }
  }

  ngOnInit() {
    this.subscriptions$.add(
      this.loadingSpinner.loadingSpinner.subscribe((data) => {
        if (data === true) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
      })
    );

    this.primengConfig.ripple = true;
    this.registerSidebarIcons();
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  registerSidebarIcons() {
    this.iconLibraries.registerSvgPack('sidenav', {
      dashboard: SIDENAV_ICON_PACK.dashboard,
      'follow-up-appointments': SIDENAV_ICON_PACK.followUpPatients,
      'hospital-booking-requests': SIDENAV_ICON_PACK.hospitalBookingRequests,
      appointments: SIDENAV_ICON_PACK.appointments,
      'manual-appointments': SIDENAV_ICON_PACK.manualAppointments,
      specializations: SIDENAV_ICON_PACK.specializations,
      patients: SIDENAV_ICON_PACK.patients,
      'medicine-delivery': SIDENAV_ICON_PACK.medicineDelivery,
      doctors: SIDENAV_ICON_PACK.doctors,
      banners: SIDENAV_ICON_PACK.banners,
      advertisement: SIDENAV_ICON_PACK.advertisement,
      chat: SIDENAV_ICON_PACK.chat,
      'daily-sale-revenue': SIDENAV_ICON_PACK.dailySaleRevenue,
      'bank-transfer': SIDENAV_ICON_PACK.walletDeduction,
      'feedbacks-and-ratings': SIDENAV_ICON_PACK.feedbacksAndRatings,
      'package-users': SIDENAV_ICON_PACK.packageUsers,
      'special-program': SIDENAV_ICON_PACK.packageDoctors,
      'feedback-logs': SIDENAV_ICON_PACK.feedbackLogs,
      'coupon-generate': SIDENAV_ICON_PACK.couponGenerate,
      'user-guide': SIDENAV_ICON_PACK.userGuide,
      'campaign-doctors': SIDENAV_ICON_PACK.campaignDoctors,
      update: SIDENAV_ICON_PACK.update,
      admins: SIDENAV_ICON_PACK.admins,
      'partner-clinic': SIDENAV_ICON_PACK.partnerClinics,
      'developer-tools': SIDENAV_ICON_PACK.devTools,
      'in-app-message': SIDENAV_ICON_PACK.inAppMessage,
    });
  }
}
