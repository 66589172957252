export const firebaseConfigs = {
  development: {
    apiKey: 'AIzaSyD0xOvyRYseWX6Nj3truEyH2P5qHYgEoew',
    authDomain: 'myancare-dev.firebaseapp.com',
    databaseURL: 'https://myancare-dev.firebaseio.com',
    projectId: 'myancare-dev',
    storageBucket: 'myancare-dev.appspot.com',
    messagingSenderId: '8419691704',
    appId: '1:8419691704:web:9cc5eb8ea916018055784d',
    measurementId: 'G-C59L7C9SCJ'
  },
  production: {
    apiKey: 'AIzaSyA6M6nMeC-loRj4jgpDUOmSJy_qWOy2txY',
    authDomain: 'myancare-b9ec0.firebaseapp.com',
    databaseURL: 'https://myancare-b9ec0.firebaseio.com',
    projectId: 'myancare-b9ec0',
    storageBucket: 'myancare-b9ec0.appspot.com',
    messagingSenderId: '106148527407',
    appId: '1:106148527407:web:97566e353cbb7d0bb6b5da',
    measurementId: 'G-3H8W5NB3SK'
  }
};
