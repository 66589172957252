// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { firebaseConfigs } from '../app/configs/firebase-config';

export const environment = {
  production: false,
  host: 'https://dev.myancare.org',
  s3bucket: 'https://myancare-reno-dev.s3.ap-southeast-1.amazonaws.com/',
  emrBaseUrl:
    'https://myancare-emr-development.s3-ap-southeast-1.amazonaws.com/',
  firebaseConfig: firebaseConfigs.development,
  zoomDashboardUrl: 'https://zoom.us/account/metrics/dashboard/home/#',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
