const target_components = {
  TOPUP: 'topup',
  DOCTOR: 'doctor',
  PATIENT: 'patient',
  SPECIALIZATION: 'specialization',
  APPOINTMENT: 'appointment',
  DAILY_REG_USER: 'daily-registered-user',
  COUPON_TOPUP_TRANSACTION: 'coupon-topup-transaction',
  PACKAGE: 'package',
  PACKAGE_USER: 'package-user',
  PACKAGE_DOCTOR_MANAGEMENT: 'special-program-management',
};

export { target_components };
