<nb-layout>
  <nb-layout-column>
    <nb-card>
      <nb-card-body>
        <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
          <div class="logo mb-4">
            <img src="assets/imgs/logo.svg" />
            <h4 class="text-center">MyanCare</h4>
          </div>
          <div class="mb-2">
            <small>Email</small>
            <input type="email" nbInput fullWidth fieldSize="small" formControlName="email">
            <div *ngIf="(f.email.invalid && (f.email.dirty || f.email.touched)) || (submitted && f.email.invalid)"
              class="validation">
              <small *ngIf="f.email.errors.required">*Email is required</small>
              <small *ngIf="f.email.errors.email && f.email.touched">*Invalid email</small>
            </div>
          </div>
          <div>
            <small>Password</small>
            <input type="password" nbInput fullWidth fieldSize="small" formControlName="password">
            <div
              *ngIf="(f.password.invalid && (f.password.dirty || f.password.touched)) || (submitted && f.password.invalid)"
              class="validation">
              <small *ngIf="f.password.errors.required">*Password is required</small>
            </div>
          </div>
          <small *ngIf="loginError" class="validation">{{ loginError }}</small>
          <div class="mt-3">
            <button fullWidth nbButton status="success" size="small" type="submit">Login</button>
          </div>
        </form>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>
</nb-layout>