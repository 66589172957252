import { Injectable, ErrorHandler } from '@angular/core';

@Injectable()
class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error) {}
}

export function getErrorHandler(): ErrorHandler {
  return new ErrorHandler();
}
