import { Component, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { LoginService } from '../services/login.service';
import { SpinnerService } from '../services/spinner.service';
import { SlackLoggerService } from '@admin-services/slack-logger.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnDestroy {
  private subscriptions$ = new Subscription();

  private auth: Auth = inject(Auth);
  submitted = false;
  loginError;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private loginServ: LoginService,
    private spinnerServ: SpinnerService,
    private slackLoggerServ: SlackLoggerService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', Validators.required),
  });

  onLogin() {
    this.submitted = true;

    if (this.loginForm.invalid) return;

    const { email, password } = this.loginForm.value;

    signInWithEmailAndPassword(this.auth, email, password)
      .then((res) => {
        this.submitted = false;

        if (res.user) {
          this.subscriptions$.add(
            this.loginServ.getAdmin().subscribe({
              next: (res: any) => {
                this.spinnerServ.loadingSpinner.next(false);
                this.routeToDashboard();
              },
              error: (error) => {
                this.spinnerServ.loadingSpinner.next(false);
                this.loginError = 'You are not authorized';
              },
            })
          );

          this.subscriptions$.add(
            this.slackLoggerServ
              .logToSlack({
                email: res.user?.email,
                name: res.user?.displayName,
                uid: res.user?.uid,
                phNo: res.user?.phoneNumber,
                type: 'LOG_IN',
              })
              .subscribe()
          );
        }
      })
      .catch((e) => {
        this.loginError = 'Email or password is invalid';
      });
  }

  private routeToDashboard() {
    this.ngZone.run(() => this.router.navigate(['/dashboard']));
  }

  get f() {
    return this.loginForm.controls;
  }
}
